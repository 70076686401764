import { FC, HTMLAttributes, type JSX } from "react";

import Link from "next/link";
import { Route } from "next";
import clsx from "clsx";
import env from "@/env";
import styles from "./Button.module.scss";

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
  cta?: boolean;
  type?: "primary" | "secondary";
  href?: string;
  linkProps?: HTMLAttributes<HTMLAnchorElement>;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = ({
  className,
  cta,
  type,
  children,
  href,
  linkProps,
  disabled = false,
  ...other
}): JSX.Element => {
  const button = (
    <button
      className={clsx(
        styles.container,
        className,
        cta && styles.cta,
        type && styles[type]
      )}
      {...other}
      disabled={disabled}
    >
      {children}
    </button>
  );
  if (href) {
    return href.startsWith("/") || href.includes(env.urls.frontend) ? (
      <Link
        href={href as Route}
        {...linkProps}
        data-nextlink="true"
        aria-disabled={disabled}
      >
        {button}
      </Link>
    ) : (
      <a
        href={href}
        {...linkProps}
        data-nextlink="false"
        aria-disabled={disabled}
      >
        {button}
      </a>
    );
  }
  return button;
};

export default Button;
